<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        รายละเอียดสินค้า
      </v-toolbar-title>
    </v-toolbar>

    <div class="mx-4 box box-2" style="min-height: 100%;overflow: auto">
      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">
        <v-col
            cols="6"
            style="height: 100%"
        >
          <v-card
              style="height: 100%;overflow: auto"
              class="px-4 pt-4 rounded-lg elevation-2"
              outlined
              tile
          >
            <!--            {{$v.name.$error}}-->
            <!--            <p class="help is-danger" v-if="!$v.name.required">This field is required</p>-->
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <!--              <v-row>-->
              <!--                <v-col cols="5" align-self="center">รหัสสินค้า</v-col>-->
              <!--                <v-col cols="7">{{ product_id }}</v-col>-->
              <!--              </v-row>-->

              <v-row>
                <v-col cols="5" align-self="center">รหัสสินค้า</v-col>
                <v-col cols="7">
                  <v-text-field v-model="id_display" :rules="[rules.required]" required :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5" align-self="center">ชื่อสินค้า</v-col>
                <v-col cols="7">
                  <v-text-field v-model="name"
                                :rules="[rules.required]"
                                required
                                :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5" align-self="center">รายละเอียดสินค้า</v-col>
                <v-col cols="7">
                  <v-text-field v-model="description" :disabled="!editable" placeholder="-" outlined
                                height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5" align-self="center">หน่วย</v-col>
                <v-col cols="7">
                  <v-text-field v-model="unit" :rules="[rules.required]" :disabled="!editable" placeholder="กรอกข้อมูล"
                                outlined
                                height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5" align-self="center">ราคาขายแบบลูกค้ามารับเอง</v-col>
                <v-col cols="7">
                  <v-text-field v-model="price_per_unit" :rules="[rules.required]" :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"
                                suffix="บาท"
                                type="number"/>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="5" align-self="center">ราคาขายแบบส่ง</v-col>
                <v-col cols="7">
                  <v-text-field v-model="price_for_ship" :rules="[rules.required,rules.min]" :disabled="!editable"
                                placeholder="กรอกข้อมูล" suffix="บาท"
                                outlined height="32px" hide-details="auto" type="number"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5" align-self="center">หมวดสินค้า</v-col>
                <v-col cols="7">
                  <v-select
                      :disabled="!editable"
                      :rules="[rules.required_select]"
                      outlined
                      dense
                      height="32px"
                      class="rounded mt-3"
                      hide-details="auto"
                      v-model="category"
                      :items="categorySelect"
                      placeholder="เลือกหมวดสินค้า"
                      item-text="name"
                      item-value="id"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5" align-self="center">มีการคิดค่าตัก</v-col>
                <v-col cols="1">
                  <v-checkbox
                      :disabled="!editable"
                      v-model="hasScoop"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5" align-self="center">มีการขนส่งแบบเที่ยวรถ</v-col>
                <v-col cols="1" align-self="center">
                  <v-checkbox
                      :disabled="!editable"
                      class="mb-2 mt-0"
                      v-model="isTrip"
                      hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-if="isTrip">
                <v-col cols="5" align-self="center">ความหนาแน่น</v-col>
                <v-col cols="3">
                  <v-text-field
                      :rules="[mustHaveDensity]"
                      :disabled="!editable"
                      v-model="density"
                      placeholder="ไม่ได้กำหนดค่า"
                      outlined height="32px"
                      hide-details="auto"
                      type="number">
                  </v-text-field>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="5" align-self="center">% คอมมิชชั่น</v-col>
                <v-col cols="7">
                  <v-text-field v-model="commission_rate" :rules="[rules.required,rules.min]" :disabled="!editable"
                                placeholder="กรอกข้อมูล" suffix="%"
                                outlined height="32px" hide-details="auto" type="number"/>
                </v-col>
              </v-row>


              <v-row v-if="(!editable) && $store.state.authUser.user_permissions.includes('change_product')"
                     class="mt-6 mx-0 mb-6">
                <v-btn depressed @click="editable = true" color="secondary">
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  แก้ไขข้อมูลสินค้า
                </v-btn>
              </v-row>

              <v-row v-if="editable && $store.state.authUser.user_permissions.includes('change_product')"
                     class="mt-6 mx-0 mb-6">
                <v-btn depressed @click="editable = false" text class="mr-4">
                  ยกเลิก
                </v-btn>
                <v-btn depressed @click="saveEditPO" color="success">
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  บันทึกการแก้ไข
                </v-btn>
              </v-row>

              <v-divider/>

              <v-row class="mt-4">
                <v-col align-self="center">
                  <span class="mr-2" style="font-size: 1.25rem">Stock สินค้า</span>
                  <v-icon v-if="!exchange"
                          @click="editDialog = true;isForCreate = true;editStockAmount='0';editStockName = ''"
                          color="success">mdi-plus-circle
                  </v-icon>
                </v-col>
              </v-row>

              <v-data-table
                  :items="stocks"
                  :headers="stockHeader"
                  hide-default-footer
                  :header-props="{ sortIcon: null }"
                  class="mt-4"
              >
                <template v-slot:item.amount="{ item }">
                  {{ item.amount.toFixed(3) }} {{ unit }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <v-btn
                        v-if="$store.state.authUser.user_permissions.includes('change_stocklocation')"
                        icon
                        color="success"
                        @click="stockSelected = item;editDialog = true;editStockName= item.location_name;editStockAmount = exchange ? 0 : item.amount;isForCreate = false"
                    >
                      <v-icon>{{ exchange ? 'mdi-plus' : 'mdi-pencil' }}</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="$store.state.authUser.user_permissions.includes('delete_stocklocation')"
                        icon
                        color="red"
                        @click="stockSelected = item;deleteConfirmDialog = true"
                        :disabled="stocks.length === 1"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template slot="body.append">
                  <tr class="primary--text">
                    <td class="font-size-5 font-bold">รวม</td>
                    <td class="text-center font-size-5 font-bold">{{ sumField('amount') }} {{ unit }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-form>
          </v-card>
        </v-col>
        <v-col
            cols="6"
            style="height: 100%"
        >
          <v-card
              style="height: 100%;overflow: auto"
              class="pa-4 pl-0 rounded-lg elevation-2"
              outlined
              tile
          >
            <!--            <h4 class="font-weight-regular">ประวัติของสินค้า</h4>-->
            <v-timeline dense align-top>
              <div v-for="transaction in transactions" :key="transaction.id">
                <v-timeline-item v-if="transaction.status ==='IN'" color="green darken-1" icon="mdi-package-down"
                                 fill-dot>
                  <v-card
                      color="green darken-1"
                      dark
                  >
                    <v-card-title style="font-size: 1.2rem;height: 40px;padding: 0 8px">
                      {{ timestampToString(transaction.timestamp) }}
                      <v-spacer></v-spacer>
                      <v-icon
                          @click="$router.push(`/stock/po/${transaction.po_item === null ? '' : transaction.po_item.po.id}`)">
                        mdi-information
                      </v-icon>
                    </v-card-title>
                    <v-card-text class="white text--primary py-2">
                      <!--                      {{ transaction.po_item === null ? '' : transaction.po_item.po.id}}-->
                      <div><b>Stock ที่เลือก : </b>{{ transaction.stock_location.location.name }}</div>
                      <div><b>จำนวน : </b>{{ transaction.amount.toFixed(3) }} {{ transaction.product.unit }}</div>
                      <div><b>ผู้ดำเนินการ : </b>{{ transaction.user.first_name + ' ' + transaction.user.last_name }}
                      </div>
                      <!--                      {{transaction}}-->
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item v-else-if="transaction.status ==='CHANGED'" color="amber darken-2" icon="mdi-pencil"
                                 fill-dot>
                  <v-card
                      color="amber darken-2"
                      dark
                  >
                    <v-card-title style="font-size: 1.2rem;height: 40px;padding: 0 8px">
                      {{ timestampToString(transaction.timestamp) }}
                      <v-spacer></v-spacer>
                      <v-icon
                          @click="$router.push(`/stock/po/${transaction.po_item === null ? '' : transaction.po_item.po.id}`)">
                        mdi-information
                      </v-icon>
                    </v-card-title>
                    <v-card-text class="white text--primary py-2">
                      <!--                      {{ transaction.po_item === null ? '' : transaction.po_item.po.id}}-->
                      <div><b>Stock ที่แก้ไข : </b>{{ transaction.stock_location.location.name }}</div>
                      <div><b>แก้ไขจำนวนจาก : </b>{{ transaction.old_amount.toFixed(3) }} {{ transaction.product.unit }}
                        เป็น
                        {{ transaction.amount.toFixed(3) }} {{ transaction.product.unit }}
                      </div>
                      <!--                      <div><b>จำนวนใหม่ : </b></div>-->
                      <div><b>ผู้ดำเนินการ : </b>{{ transaction.user.first_name + ' ' + transaction.user.last_name }}
                      </div>
                      <!--                      {{transaction}}-->
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item v-else-if="transaction.status ==='OUT'" color="blue darken-2" icon="mdi-cart"
                                 fill-dot>
                  <v-card
                      color="blue darken-2"
                      dark
                  >
                    <v-card-title style="font-size: 1.2rem;height: 40px;padding: 0 8px">
                      {{ timestampToString(transaction.timestamp) }}
                      <v-spacer></v-spacer>
                      <v-icon
                          @click="$router.push(`/order/${transaction.order_item === null ? '' : transaction.order_item.order.id}`)">
                        mdi-information
                      </v-icon>
                    </v-card-title>
                    <v-card-text class="white text--primary py-2">
                      <!--                      {{ transaction.po_item === null ? '' : transaction.po_item.po.id}}-->
                      <div><b>ลูกค้า : </b>{{ transaction.order_item.order.customer_name }}</div>
                      <div><b>Stock ที่ตัด : </b>{{ transaction.stock_location.location.name }}</div>
                      <div><b>ยอดเก่า :</b> {{ transaction.old_amount.toFixed(3) }} {{ transaction.product.unit }}</div>
                      <div><b>จำนวน :</b> {{ transaction.amount.toFixed(3) }} {{ transaction.product.unit }}</div>
                      <div><b>คงเหลือ :</b> {{ (transaction.old_amount - transaction.amount).toFixed(3) }} {{
                          transaction.product.unit
                        }}
                      </div>
                      <!--                      <div><b>แก้ไขจำนวนจาก : </b>{{ transaction.old_amount }} {{ transaction.product.unit }} เป็น-->
                      <!--                        {{ transaction.amount }} {{ transaction.product.unit }}-->
                      <!--                      </div>-->
                      <!--                      <div><b>จำนวนใหม่ : </b></div>-->
                      <div><b>ผู้ดำเนินการ : </b>{{ transaction.user.first_name + ' ' + transaction.user.last_name }}
                      </div>
                      <!--                      {{transaction}}-->
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item v-else-if="transaction.status ==='CANCEL_ACCEPTED_PO'" color="red darken-2" icon="mdi-file-cancel"
                                 fill-dot>
                  <v-card
                      color="red darken-2"
                      dark
                  >
                    <v-card-title style="font-size: 1.2rem;height: 40px;padding: 0 8px">
                      {{ timestampToString(transaction.timestamp) }} (ยกเลิก PO)
                      <v-spacer></v-spacer>
                      <v-icon
                          @click="$router.push(`/stock/po/${transaction.po_item === null ? '' : transaction.po_item.po.id}`)">
                        mdi-information
                      </v-icon>
                    </v-card-title>
                    <v-card-text class="white text--primary py-2">
                      <!--                      {{ transaction.po_item === null ? '' : transaction.po_item.po.id}}-->
                      <div><b>Stock ยกเลิก PO : </b>{{ transaction.stock_location.location.name }}</div>
                      <div><b>ปรับ Stock คืนจาก : </b><span class="red--text">{{ transaction.old_amount.toFixed(3) }} {{ transaction.product.unit }}</span>
                        เป็น
                        {{ transaction.amount.toFixed(3) }} {{ transaction.product.unit }}
                      </div>
                      <!--                      <div><b>จำนวนใหม่ : </b></div>-->
                      <div><b>ผู้ดำเนินการ : </b>{{ transaction.user.first_name + ' ' + transaction.user.last_name }}
                      </div>
                      <!--                      {{transaction}}-->
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </div>
            </v-timeline>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!--    edit dialog-->
    <v-dialog
        v-model="editDialog"
        persistent
        :max-width="exchange ? 512 : 428"
    >
      <template v-slot:activator="{ on, attrs }">
      </template>
      <v-card>
        <v-card-title>
          <p>{{ isForCreate ? "เพิ่ม Stock ใหม่" : "แก้ไข" }}</p>
        </v-card-title>
        <v-card-text>
          <v-form
              ref="form"
              v-model="validEdit"
              lazy-validation
          >
            <v-row>
              <v-col cols="4">
                ชื่อ Stock
              </v-col>
              <v-col>
                <v-text-field v-model="editStockName"
                              :rules="[rules.required]"
                              hide-details="auto"
                              placeholder="ใส่ชื่อของ Stock"
                              outlined>
                </v-text-field>
              </v-col>
            </v-row>
            <!--            {{ exchangeStock }}-->


            <v-row>
              <v-col v-if="exchange" cols="4">
                จำนวนที่เพิ่ม
              </v-col>
              <v-col v-else cols="4">
                จำนวนคงเหลือ
              </v-col>
              <v-col>
                <v-text-field v-model="editStockAmount"
                              type="number"
                              placeholder="ใส่ชื่อจำนวน"
                              :rules="[rules.required]"
                              hide-details="auto"
                              outlined>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="exchange && !isForCreate">
              <v-col cols="4">
                ตัดจาก Stock
              </v-col>
              <v-col>
                <v-select
                    return-object
                    v-model="exchangeStock"
                    outlined
                    dense
                    height="32px"
                    class="rounded"
                    hide-details="auto"
                    :items="exchange.from_product.stock_location"
                    item-value="stock_location_id"
                    :rules="[rules.required]"
                >
                  <template slot="selection" slot-scope="data">
                    <div style="display: flex;width: 100%;justify-content: space-between">
                      <span>{{ data.item.location_name }}</span>
                      <span><span style="color: #265a88">เหลือ {{
                          data.item.amount
                        }} {{ exchange.from_product.unit }}</span></span>
                    </div>
                  </template>
                  <template slot="item" slot-scope="data">
                    <div style="display: flex;width: 100%;justify-content: space-between">
                      <span>{{ data.item.location_name }}</span>
                      <span><span style="color: #265a88">เหลือ {{
                          data.item.amount
                        }} {{ exchange.from_product.unit }}</span></span>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row v-if="exchange && exchangeStock && !isForCreate">
              <v-col class="blue--text" style="font-size: 1.125rem">
                หักออกจาก Stock <span class="orange--text">{{ exchangeStock.location_name }}</span> จำนวน <span
                  class="red--text">{{ calCutStockFrom }}</span> {{ exchange.from_product.unit }}
              </v-col>
            </v-row>

            <v-row v-if="exchange">
              <v-col class="red--text">
                {{ exchange.from_product.name }} {{ exchange.from_rate }} {{ exchange.from_product.unit }} =
                {{ exchange.to_product.name }} {{ exchange.to_rate }} {{ exchange.to_product.unit }}
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              depressed
              text
              @click="editDialog = false;stockSelected = {}"
          >
            ยกเลิก
          </v-btn>
          <v-btn
              depressed
              v-if="isForCreate"
              color="success"
              @click="addNewStock()"
          >
            <v-icon left>mdi-plus</v-icon>
            ยืนยันการเพิ่ม Stock ใหม่
          </v-btn>
          <v-btn
              depressed
              v-else
              color="success"
              @click="saveEditStock()"
          >
            <v-icon left>mdi-pencil</v-icon>
            ยืนยันการแก้ไข
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    confirm delete dialog-->
    <v-dialog
        v-model="deleteConfirmDialog"
        persistent
        max-width="428"
    >
      <template v-slot:activator="{ on, attrs }">
      </template>
      <v-card>
        <v-card-title>
          <p>คุณแน่ใจต้องการที่จะลบ Stock สินค้าชื่อว่า<br> <span
              style="color: red">"{{ stockSelected.location_name }}"</span> และ{{ name }}อีกจำนวน <span
              style="color: red">{{ stockSelected.amount }} {{ unit }}</span> หรือไม่</p>
        </v-card-title>
        <v-card-text>
          การกระทำดังกล่าวไม่สามารถย้อนกลับได้
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="deleteConfirmDialog = false;stockSelected = {}"

          >
            ยกเลิก
          </v-btn>
          <v-btn
              color="error"
              @click="deleteStock()"
          >
            <v-icon left>mdi-delete</v-icon>
            ยืนยันการลบ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import DateTime from 'luxon/src/datetime.js'
import _ from 'lodash'

export default {
  name: "CreateProduct",
  data() {
    return {
      isForCreate: false,
      product_id: 0,
      id_display: '',
      price_for_ship: 0,
      commission_rate: 0,
      valid: true,
      validEdit: true,
      editable: false,
      name: '',
      description: '',
      unit: '',
      price_per_unit: 0,
      category: 0,
      categorySelect: [],
      hasScoop: false,
      stocks: [],
      rules: {
        required: value => !!value || "ต้องกรอก",
        required_select: value => !!value || "เลือกหมวดสินค้า",
        min: value => value >= 0 || "จำนวนต้องเป็นตั้งแต่ 0 ขึ้นไป"
      },
      transactions: [],
      deleteConfirmDialog: false,
      editDialog: false,
      stockSelected: {},
      stockHeader: [
        {
          text: 'ชื่อ Stock',
          value: 'location_name',
          class: "blue lighten-5"
        },
        {
          text: 'จำนวนสินค้าคงเหลือ',
          value: 'amount',
          align: 'center',
          class: "blue lighten-5"
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          class: "blue lighten-5",
          width: 80
        },
      ],
      editStockName: "",
      editStockAmount: "",
      exchange: null,
      exchangeStock: null,
      isTrip: false,
      density: '',
    }
  },
  async created() {
    this.$store.state.isLoading = true
    try {
      await this.getCategory()
      this.getProductData()
      this.getTransaction()
      this.getExchange()
    } catch (e) {
      this.$store.state.snackbar = {
        text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
        visible: true,
        color: 'error'
      }
    } finally {
      this.$store.state.isLoading = false
    }
  },
  methods: {
    mustHaveDensity(value) {
      if (value.length === 0 || parseFloat(value) === 0) {
        if (this.isTrip) {
          return "ถ้าติ้ก มีการส่งแบบเที่ยวรถ จะต้องกรอกค่าความหนาแน่น (ตัวหาร)"
        }
        return true
      } else {
        return true;
      }
    },
    timestampToString(timestamp) {
      return DateTime.fromISO(timestamp).setLocale('th').toFormat('dd LLL yyyy เวลา HH:mm');
    },
    getCategory() {
      axios.get('/api/stocks/category/').then(res => {
        // res.data.data.forEach(c => this.categorySelect.push(c.name))
        this.categorySelect = res.data.data
      })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    getProductData() {
      // this.lots.push({location_name: this.name, amount: 0,})
      axios.get(`/api/stocks/product/${this.$route.params.id}/`)
          .then(res => {
            let [product] = res.data.data
            this.product_id = product.id
            this.name = product.name
            this.description = product.description
            this.unit = product.unit
            this.price_per_unit = product.price
            this.category = product.category.id
            this.hasScoop = product.hasCom
            this.stocks = product.stock_location
            this.density = parseFloat(product.density) ? product.density : ''
            this.isTrip = product.isTrip
            this.id_display = product.id_display
            this.price_for_ship = product.price_for_ship
            this.commission_rate = product.commission_rate
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    getTransaction() {
      axios.get(`/api/stocks/transaction/${this.$route.params.id}/`)
          .then(res => {
            res.data.data.forEach(t => {
              // if (!(parseFloat(t.amount) === 0)) {
              this.transactions.push(t)
              console.log(this.transactions)
              // }
              this.transactions = _.orderBy(this.transactions, 'timestamp', 'desc')
            })
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    getExchange() {
      axios.get(`/api/stocks/exchange/${this.$route.params.id}/`)
          .then(res => {
            if (res.data.data.length > 0) {
              [this.exchange] = res.data.data
            }
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    async saveEditPO() {
      await this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      this.editable = false
      let payload = {
        id: this.product_id,
        category: this.category,
        name: this.name,
        description: this.description ? this.description : '',
        unit: this.unit,
        price: parseFloat(this.price_per_unit),
        hasCom: this.hasScoop,
        isTrip: this.isTrip,
        density: this.density ? this.density : 0,
        id_display: this.id_display,
        price_for_ship: parseFloat(this.price_for_ship),
        commission_rate: parseFloat(this.commission_rate),
      }

      axios.put(`/api/stocks/product/`, payload)
          .then(() => {
            this.$store.state.snackbar = {
              text: `บันทึกการแก้ไขข้อมูลสินค้าแล้ว`,
              visible: true,
              color: 'success'
            }
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })

    },
    sumField(key) {
      return (this.stocks.reduce((a, b) => a + (b[key] || 0), 0)).toFixed(3)
    },
    async addNewStock() {
      await this.$refs.form.validate()
      if (this.validEdit) {
        let payload = {
          location_name: this.editStockName,
          product_id: this.product_id,
          amount: parseFloat(this.editStockAmount)
        }
        // console.log(payload)
        axios.post('/api/stocks/stock_location/', payload)
            .then(() => {
              this.$store.state.snackbar = {
                text: `เพิ่ม Stock ${this.editStockName} สำเร็จ`,
                visible: true,
                color: 'success'
              }
              this.editDialog = false
              this.getProductData()
            }).catch(e => {
          this.$store.state.snackbar = {
            text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
            visible: true,
            color: 'error'
          }
        })
      }
    },
    async saveEditStock() {
      await this.$refs.form.validate()
      if (this.validEdit) {

        let payload = {
          id: this.stockSelected.stock_location_id,
          location_name: this.editStockName,
          amount: this.exchange ? parseFloat(this.stockSelected.amount) + parseFloat(this.editStockAmount) : parseFloat(this.editStockAmount).toFixed(3)
        }

        await axios.put('/api/stocks/stock_location/', payload)
            .then(() => {

              if (this.exchange) {
                let payloadCut = {
                  id: this.exchangeStock.stock_location_id,
                  location_name: this.exchangeStock.location_name,
                  amount: this.exchangeStock.amount - this.calCutStockFrom
                }

                axios.put('/api/stocks/stock_location/', payloadCut)
                    .then(() => {
                      this.$store.state.snackbar = {
                        text: `แก้ไข Stock สำเร็จ`,
                        visible: true,
                        color: 'success'
                      }
                      this.editDialog = false
                      this.getProductData()

                      // window.location.reload();
                    }).catch(e => {
                  this.$store.state.snackbar = {
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
                    visible: true,
                    color: 'error'
                  }
                })

              } else {
                this.$store.state.snackbar = {
                  text: `แก้ไข Stock สำเร็จ`,
                  visible: true,
                  color: 'success'
                }
                this.editDialog = false
                this.getProductData()
              }


            }).catch(e => {
              this.$store.state.snackbar = {
                text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
                visible: true,
                color: 'error'
              }
            })


      }
    },
    deleteStock() {
      if (this.stocks.length > 1) {
        axios.delete(`/api/stocks/stock_location/${this.stockSelected.stock_location_id}/`)
            .then(() => {
              this.deleteConfirmDialog = false
              this.getProductData()
              this.$store.state.snackbar = {
                text: `ลบ Stock สำเร็จ`,
                visible: true,
                color: 'success'
              }
            })
            .catch(e => {
              this.$store.state.snackbar = {
                text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
                visible: true,
                color: 'error'
              }
            })
      }
    },
  },
  computed: {
    calCutStockFrom() {
      if (this.exchange) {
        let f = parseFloat(this.exchange.from_rate)
        let t = parseFloat(this.exchange.to_rate)
        let v = parseFloat(this.editStockAmount)
        return ((v / t) * f).toFixed(3)
      }
      return ""
    }
  }
}
</script>

<style lang="scss">
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0;
  margin-bottom: 0 !important;
}

//[class$="-disabled"],
//[class*="-disabled "] * {
//  color: #000 !important;
//  border-color: #e5e5e5 !important;
//}
</style>
